import styled, { css } from 'styled-components';
import {
    H2,
    h1Styles,
    h1VariantStyles,
    h1LargeStyles,
    h1SmallStyles,
    h2Styles,
    h2LargeStyles,
    h3Styles,
    P,
} from 'typography';
import ImageLazyLoad from 'components/ImageLazyLoad';
import Link from 'components/Link';
import { IconArrowRight, IconChevronRight } from 'components/Icon';
import { ImagePosition, InformationSectionVariants } from './InformationSection.constants';

const InformationSectionStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-bottom: ${({ theme }) => theme.spacing[4.5]};

    &:last-of-type {
        margin-bottom: ${({ theme }) => theme.spacing[0]};
    }

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex-direction: row;
        align-items: center;
        flex-direction: ${({ imagePosition }) =>
            imagePosition === ImagePosition.RIGHT ? 'row-reverse' : 'row'};
        margin-bottom: ${({ theme }) => theme.spacing[12]};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        margin-bottom: ${({ theme }) => theme.spacing[12]};
    }
`;

const ImgContainerStyled = styled.div`
    min-height: 1px; // Fix for IE11 not respecting a scaled image's new height (https://github.com/philipwalton/flexbugs/issues/75)
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: 50%;
        margin-bottom: ${({ theme }) => theme.spacing[0]};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        width: 50%;
        margin-bottom: ${({ theme }) => theme.spacing[0]};
    }
`;

const ImageLazyLoadStyled = styled(ImageLazyLoad)`
    border-radius: ${({ theme: { borderRadius } }) => borderRadius.regular};
`;

// TEXTCONTAINER STYLES
// This is specifically for sharing styles between
// regular and large variants, except for at 1280px+ breakpoints
const textContainerSharedStyles = css`
    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: 50%;
        margin-right: ${({ imagePosition, theme }) =>
            imagePosition === ImagePosition.RIGHT ? theme.spacing[4] : 0};
        margin-bottom: ${({ theme }) => theme.spacing[0]};
        padding-right: ${({ imagePosition, theme }) =>
            imagePosition === ImagePosition.RIGHT ? 0 : theme.spacing[3.5]};
        padding-left: ${({ theme }) => theme.spacing[3.5]};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[6]}`};
    }
`;

const textContainerVariantStyles = {
    [InformationSectionVariants.SMALL]: css`
        ${textContainerSharedStyles};
    `,
    [InformationSectionVariants.REGULAR]: css`
        ${textContainerSharedStyles};

        /* 720 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            margin-bottom: ${({ theme }) => theme.spacing[0]};
            padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[6]}`};
        }
    `,
    [InformationSectionVariants.LARGE]: css`
        ${textContainerSharedStyles};

        /* 720 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            margin-bottom: ${({ theme }) => theme.spacing[0]};
            padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[6]}`};
        }
    `,
    [InformationSectionVariants.PRISMIC_HERO]: css`
        ${textContainerSharedStyles};

        /* 720 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            margin-bottom: ${({ theme }) => theme.spacing[0]};
            padding-right: ${({ imagePosition, theme }) =>
                imagePosition === ImagePosition.RIGHT ? 0 : theme.spacing[6]};
            padding-left: ${({ theme }) => theme.spacing[6]};
        }

        /* 1024 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
            padding-right: ${({ imagePosition, theme }) =>
                imagePosition === ImagePosition.RIGHT ? 0 : theme.spacing[6]};
            padding-left: ${({ theme }) => theme.spacing[6]};
        }
    `,
};

const TextContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ variant }) => textContainerVariantStyles[variant]};
`;

// HEADING STYLES
// These are shared between small and large variant until 1280px+ breakpoint
const headingSharedStyles = css`
    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        ${h2Styles};
    }

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        ${h2LargeStyles};
        /* Line-height set here to override h2LargeStyles line-height as it looks better at this screen size */
        line-height: 1.3;
    }
`;

const headingVariantStyles = {
    [InformationSectionVariants.SMALL]: css`
        ${h3Styles};

        margin-bottom: ${({ theme }) => theme.spacing[2]};

        ${headingSharedStyles};

        /* 800 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
            ${h2Styles};
        }
    `,
    [InformationSectionVariants.REGULAR]: css`
        ${headingSharedStyles};

        /* 1280 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
            ${h2LargeStyles};
        }
    `,
    [InformationSectionVariants.LARGE]: css`
        ${headingSharedStyles};

        /* 1280 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
            ${h1VariantStyles};
        }
    `,
    [InformationSectionVariants.PRISMIC_HERO]: css`
        ${h1SmallStyles};
        margin-bottom: ${({ theme }) => theme.spacing[2]};

        /* 720 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            ${h1Styles}
            margin-bottom: ${({ theme }) => theme.spacing[3]};
        }

        /* 1024 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
            margin-bottom: ${({ theme }) => theme.spacing[3]};
        }

        /* 1280 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
            ${h1LargeStyles};
        }

        /* 1440 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
            margin-bottom: ${({ theme }) => theme.spacing[4]};
        }
    `,
};

const HeadingStyled = styled(H2)`
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    ${({ variant }) => headingVariantStyles[variant]};
`;

// PARAGRAPH STYLES
const paragraphVariantStyles = {
    [InformationSectionVariants.SMALL]: css``,
    [InformationSectionVariants.REGULAR]: css``,
    [InformationSectionVariants.LARGE]: css`
        /* 1280 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
            font-size: 20px;
            line-height: 1.6;
        }
    `,
    [InformationSectionVariants.PRISMIC_HERO]: css`
        margin-bottom: ${({ theme }) => theme.spacing[2]};

        /* 1024 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
            margin-bottom: ${({ theme }) => theme.spacing[3]};
        }

        /* 1280 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
            font-size: 20px;
            line-height: 1.6;
        }

        /* 1440 */
        @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
            margin-bottom: ${({ theme }) => theme.spacing[4]};
        }
    `,
};

const ParagraphStyled = styled(P)`
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[1.5]};

    ${({ variant }) => paragraphVariantStyles[variant]};
`;

const LinkStyled = styled(Link)`
    justify-content: start;
    padding-left: ${({ theme }) => theme.spacing[0]};
    padding-right: ${({ theme }) => theme.spacing[0]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const IconArrowRightStyled = styled(IconArrowRight)`
    margin-left: ${({ theme }) => theme.spacing[1]};
    & svg {
        fill: ${({ theme }) => theme.palette.link};
    }
`;

const CTALinkStyled = styled(Link)(
    ({ theme: { spacing } }) => css`
        padding: ${spacing[1.5]} ${spacing[1]} ${spacing[1.5]} ${spacing[2]};
        text-align: left;
        margin: ${spacing[1.5]} ${spacing[0]};
        min-width: 192px;
        max-width: 284px;
    `
);

const CTALinkLabelStyled = styled.span`
    flex: 1;
`;

const CTAIconChevronRightStyled = styled(IconChevronRight)(
    ({ theme: { palette } }) => css`
        && {
            & svg {
                fill: ${palette.highlight};
            }
        }
    `
);

export {
    InformationSectionStyled,
    ImgContainerStyled,
    TextContainerStyled,
    HeadingStyled,
    ParagraphStyled,
    LinkStyled,
    IconArrowRightStyled,
    CTALinkStyled,
    CTALinkLabelStyled,
    CTAIconChevronRightStyled,
    ImageLazyLoadStyled,
};
