import React from 'react';
import {
    InformationSectionStyled,
    TextContainerStyled,
    ImageLazyLoadStyled,
    ImgContainerStyled,
    HeadingStyled,
    ParagraphStyled,
    LinkStyled,
    IconArrowRightStyled,
    CTALinkStyled,
    CTALinkLabelStyled,
    CTAIconChevronRightStyled,
} from './InformationSection.style';
import { ImagePosition, InformationSectionVariants } from './InformationSection.constants';

const InformationSection = ({
    heading,
    textBody,
    url,
    linkText,
    imagePosition,
    image,
    variant,
    ctaOptions,
    alt,
}) => {
    const CtaLink = () =>
        ctaOptions.customCTA ? (
            <ctaOptions.customCTA />
        ) : (
            <CTALinkStyled variant={ctaOptions.variant} to={url} onClick={ctaOptions.onClick}>
                <CTALinkLabelStyled>{linkText}</CTALinkLabelStyled>
                <CTAIconChevronRightStyled size="medLarge" />
            </CTALinkStyled>
        );

    const SectionLink = () =>
        ctaOptions ? (
            <CtaLink />
        ) : (
            <LinkStyled variant="link" to={url}>
                {linkText}
                <IconArrowRightStyled size="small" />
            </LinkStyled>
        );

    return (
        <InformationSectionStyled imagePosition={imagePosition}>
            <ImgContainerStyled imagePosition={imagePosition}>
                <ImageLazyLoadStyled alt={alt} src={image} />
            </ImgContainerStyled>
            <TextContainerStyled variant={variant} imagePosition={imagePosition}>
                <HeadingStyled variant={variant}>{heading}</HeadingStyled>
                <ParagraphStyled variant={variant}>{textBody}</ParagraphStyled>
                {url ? <SectionLink /> : null}
            </TextContainerStyled>
        </InformationSectionStyled>
    );
};

const CustomInformationSection = ({ Component, heading, textBody, url, linkText }) => {
    // hardcoded variant for the custom version right now as we don't know how it behaves
    // and this is just a for a test page. TODO: refactor appropriately if we polish the page more.
    const variant = InformationSectionVariants.REGULAR;
    return (
        <InformationSectionStyled>
            <ImgContainerStyled>
                <Component />
            </ImgContainerStyled>
            <TextContainerStyled variant={variant}>
                <HeadingStyled variant={variant}>{heading}</HeadingStyled>
                <ParagraphStyled variant={variant}>{textBody}</ParagraphStyled>
                {url ? (
                    <LinkStyled variant="link" to={url}>
                        {linkText}
                        <IconArrowRightStyled size="small" />
                    </LinkStyled>
                ) : null}
            </TextContainerStyled>
        </InformationSectionStyled>
    );
};

InformationSection.defaultProps = {
    variant: InformationSectionVariants.REGULAR,
};

InformationSection.Variant = InformationSectionVariants;
InformationSection.ImagePosition = ImagePosition;

export { CustomInformationSection, InformationSection as default };
