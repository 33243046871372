const ImagePosition = {
    RIGHT: 'right',
    LEFT: 'left',
};

const InformationSectionVariants = {
    SMALL: 'small',
    REGULAR: 'regular',
    LARGE: 'large',
    PRISMIC_HERO: 'prismicHero',
};

export { ImagePosition, InformationSectionVariants };
